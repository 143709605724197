var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createPaymentNote, getAllPaymentNotes, getSinglePaymentNote, deletePaymentNote, deleteManyPaymentNotes, } from './actions';
var initialState = {
    paymentNotes: { docs: [], meta: {} },
    paymentNote: {},
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'paymentNote',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(createPaymentNote.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createPaymentNote.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.paymentNotes.docs = __spreadArray(__spreadArray([], state.paymentNotes.docs, true), [payload], false);
        })
            .addCase(createPaymentNote.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getAllPaymentNotes.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllPaymentNotes.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.paymentNotes = payload;
        })
            .addCase(getAllPaymentNotes.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getSinglePaymentNote.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getSinglePaymentNote.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.paymentNote = __assign({}, payload.data);
        })
            .addCase(getSinglePaymentNote.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deletePaymentNote.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deletePaymentNote.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.paymentNotes.docs = state.paymentNotes.docs.filter(function (note) { return note._id !== payload.id; });
        })
            .addCase(deletePaymentNote.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteManyPaymentNotes.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteManyPaymentNotes.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.paymentNotes.docs = state.paymentNotes.docs.filter(function (note) { return !payload.paymentNoteIds.includes(note._id); });
        })
            .addCase(deleteManyPaymentNotes.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
